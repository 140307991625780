@import "_generated-font-mixins.scss";
.accordion {
  &__title {
    position: relative;

    overflow: hidden;

    margin-bottom: 1px;
    padding: 17px 0;

    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    color: #333;

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      right: 2px;
      transform: translateY(-50%) rotate(135deg);

      display: block;

      width: 11px;
      height: 11px;

      border-top: 2px solid #333;
      border-right: 2px solid #333;

      transition: 0.2s;
    }
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s;

    &__item {
      overflow: hidden;
      padding: 17px 0;
      font-size: 15px;
      line-height: 18px;

      & a {
        font-weight: normal;
        color: #2d82eb;
        text-decoration: none;
      }

      &__key {
        float: left;
        width: 50%;
      }

      &__value {
        float: left;
        width: 50%;
        font-weight: 700;
        text-align: right;
      }
    }
  }

  &.open .accordion__title::after {
    transform: translateY(-50%) rotate(-45deg);
  }

  &.open .accordion__content {
    max-height: 2000px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid rgb(227 227 227 / 100%);
}
