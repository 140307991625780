@import "_generated-font-mixins.scss";
.wrapper {
  position: fixed;
  z-index: 999;
  bottom: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 55px;

  background-color: #fff;
  border-top: 1px solid rgb(0 0 0 / 12%);
}
