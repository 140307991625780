@import "_generated-font-mixins.scss";
/* stylelint-disable order/order */

.sidebar {
  @media screen and (height <= 1000px) {
    overflow-y: scroll;
  }

  &__wrapper {
    margin-bottom: 100px;
  }

  position: absolute;
  top: 0;
  left: -265px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 265px;
  height: 100vh;
  padding-bottom: 50px;

  background-color: #474d59;

  &__userblock {
    display: flex;

    width: 100%;
    height: 102px;
    padding: 20px 0 22px 15px;

    color: #fff;
    text-align: left;

    border-bottom: 1px solid #424853;

    &__avatar {
      margin-right: 10px;
      border-radius: 50%;
    }

    &__balance {
      font-size: 14px;
      opacity: 0.6;
    }
  }

  &__separator {
    display: block;
    width: 100%;
    height: 10px;
    background-color: #5c626e;
  }

  &__link {
    position: relative;

    display: flex;
    gap: 16px;
    align-items: center;

    width: 100%;
    height: 44px;
    padding: 12.5px 15px 12.5px 16px;

    color: #fff;
    white-space: nowrap;

    border-bottom: 1px solid #424853;

    &__hidden {
      position: absolute;
      font-size: 0;
      line-height: 0;
      visibility: hidden;
    }

    &__lang {
      position: absolute;
      right: 26px;

      padding: 2px;

      font-size: 10px;
      color: #474d59;

      background-color: #fff;
      border-radius: 4px;
    }

    &__active {
      background-color: #32363e;
    }
  }
}

.disable__click * {
  pointer-events: none !important;
}

.icon__lang {
  display: flex;
  justify-content: center;
  width: 24px;

  &::before {
    @include icon-lang;

    font-size: 12px;
    color: #b5b8bc;
  }
}

.icon__home {
  &::before {
    @include icon-home;

    color: #b5b8bc;
  }
}

.icon__market {
  &::before {
    @include icon-market;

    color: #b5b8bc;
  }
}

.icon__blog {
  display: flex;
  justify-content: center;
  width: 24px;

  &::before {
    @include icon-blog;

    font-size: 17px;
    color: #b5b8bc;
  }
}

.icon__info {
  &::before {
    @include icon-info;

    color: #b5b8bc;
  }
}

.icon__phone {
  &::before {
    @include icon-phone;

    color: #b5b8bc;
  }
}

.icon__email {
  &::before {
    @include icon-mail;

    color: #b5b8bc;
  }
}

.icon__add__post {
  &::before {
    @include icon-post-ad;

    color: #b5b8bc;
  }
}

.icon__add__pay {
  &::before {
    @include icon-ad-pay;

    color: #b5b8bc;
  }
}

.icon__wallet {
  &::before {
    @include icon-wallet;

    color: #b5b8bc;
  }
}

.icon__subscriptions {
  &::before {
    @include icon-subscriptions;

    color: #b5b8bc;
  }
}

.icon__contacts {
  &::before {
    @include icon-phone;

    color: #b5b8bc;
  }
}

.icon__history {
  &::before {
    @include icon-history-view;

    color: #b5b8bc;
  }
}

.icon__favorite {
  &::before {
    @include icon-heart;

    color: #b5b8bc;
  }
}

.icon__my__ads {
  &::before {
    @include icon-my-ads;

    color: #b5b8bc;
  }
}

.icon__business {
  &::before {
    @include icon-business;

    color: #b5b8bc;
  }
}

.icon__booking {
  &::before {
    @include icon-booking;

    font-size: 22px;
    color: #b5b8bc;
  }
}

.icon__payment {
  &::before {
    @include icon-payment;

    font-size: 22px;
    color: #b5b8bc;
  }
}

.icon__messages {
  width: 23px;

  &::before {
    @include icon-message;

    font-size: 19px;
    color: #b5b8bc;
  }
}

.icon__notifications {
  &::before {
    @include icon-notifications;

    color: #b5b8bc;
  }
}

.icon__settings {
  &::before {
    @include icon-settings;

    color: #b5b8bc;
  }
}

.icon__mail {
  &::before {
    @include icon-mail;

    color: #b5b8bc;
  }
}
