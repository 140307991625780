@import "_generated-font-mixins.scss";
.infocar {
  width: 100%;
  margin-bottom: 30px;
  padding: 30px;

  border: 1px solid #e3e3e3;
  border-radius: 4px;

  &__top {
    width: 100%;
    margin-bottom: 25px;

    &__logo {
      width: 74px;
      height: 26px;
      margin-bottom: 8px;

      background-repeat: no-repeat;
      background-size: contain;
    }

    &__title {
      margin-bottom: 10px;

      font-size: 24px;
      font-weight: 700;
      line-height: 27px;
      color: #555;
    }

    &__subtitle {
      font-size: 15px;
      font-weight: 400;
      line-height: 17px;
      color: #999;
    }
  }

  &__general {
    width: 100%;
    height: 42px;
    padding: 0 32px;

    font-size: 14px;

    border-radius: 4px;
  }

  &__order {
    margin: 10px 0 20px;
    color: #fff;
    background-color: #3164be;
  }

  &__example {
    color: #3164be;
    border: 1px solid #3164be;
  }
}
