@import "_generated-font-mixins.scss";
@mixin icon-style($size, $color) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: $size;
  color: $color;
}

.nav {
  &__item {
    position: relative;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 50px;

    text-decoration: none;

    & a,
    button {
      position: relative;

      display: flex;
      justify-content: center;

      width: 100%;
      height: 100%;
    }
  }

  &__element {
    width: 24px;
    height: 24px;
    padding: 8px 13px 24px;

    text-align: center;
    text-decoration: none;

    background-repeat: no-repeat;
    background-position: center center;
  }
}

.notification {
  &__counter_wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;

    width: 35px;
    height: 30px;
    margin-right: -50%;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: 12px;
    padding: 0 4px;

    font-size: 9px;
    font-weight: 400;
    line-height: 10px;
    color: #fff;

    background-color: #ff453a;
    border: 1px solid #fff;
    border-radius: 100px;
  }
}

.icon {
  &__home,
  &__messages,
  &__post_ad,
  &__notifications,
  &__my_ads {
    position: relative;

    &::after {
      @include icon-style(24px, rgb(85, 85, 85) !important);
    }
  }

  &__home::after {
    @include icon-home;

    font-size: 26px;
    font-weight: 500;
  }

  &__messages::after {
    @include icon-message;

    font-size: 21px;
  }

  &__post_ad::after {
    @include icon-plus-bg;

    font-size: 26px;
    color: rgb(0 107 184) !important;
  }

  &__notifications::after {
    @include icon-notification_nav;

    font-size: 24px;
  }

  &__my_ads::after {
    @include icon-my-ads;

    font-size: 25px;
  }
}

.publicate {
  position: absolute;
  bottom: 8px;

  font-size: 10px;
  line-height: 12px;
  color: #555;
}
