@import "_generated-font-mixins.scss";
.search {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;

  overflow-y: hidden;

  width: 100%;
  height: calc(100vh - 50px);

  background-color: #fff;

  &__bar__gray {
    display: flex;
    align-items: center;

    width: 100%;
    height: 51px;

    background-color: #ececef;
  }

  &__bar__white {
    display: flex;
    align-items: center;

    width: 100%;
    height: 51px;

    background-color: #fff;
  }

  &__input__wrapper {
    position: relative;
    width: 80%;
    height: calc(100% - 10px);
    margin-left: 10px;

    &::before {
      @include icon-search-btn;

      position: absolute;
      top: 8px;
      left: 12px;

      font-size: 16px;
      color: #cccccd;
    }
  }

  &__input__field {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 7px 25px 7px 33px;

    font-size: 15px;
    color: #333;

    appearance: none;
    background: #fff;
    background-size: 17px;
    border: 1px solid #dfe0e4;
    border-radius: 4px;

    &:focus {
      border: 1px solid #dfe0e4;
    }
  }

  &__input__clear {
    position: absolute;
    top: -5px;
    right: 1%;

    width: 38px;
    height: 51px;

    &::before {
      content: '';

      position: absolute;
      top: 15.5px;
      right: 10px;

      display: block;

      width: 18px;
      height: 18px;

      opacity: 0.3;
      background-color: #000;
      border-radius: 50%;
    }

    &::after {
      @include icon-cancel;

      position: absolute;
      top: 17px;
      right: 10px;

      width: 18px;

      font-size: 11px;
      color: #fff;
    }
  }

  &__cancel {
    width: 20%;
    height: 51px;

    font-size: 14px;
    color: #2d82eb;

    background-color: transparent;
  }
}

.results {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 50px);

  &__item__wrapper {
    border-top: solid 2px #f5f5f5;

    &:first-of-type {
      border-top: 0;
    }
  }

  &__item {
    cursor: pointer;

    width: 100%;
    padding: 13.5px 35px 13.5px 25px;

    font-size: 14px;
    line-height: 15px;
    color: #000;
    text-align: start;
    list-style-type: none;
  }
}

.icon__search {
  width: 42px;

  &::before {
    @include icon-search-btn;

    font-size: 18px;
    color: #555;
  }
}
