@import "_generated-font-mixins.scss";
.header {
  width: 100%;

  &__logo {
    flex: 0 0 73px;
  }

  &__add {
    &__ad {
      display: flex;
      align-items: center;
      justify-content: center;

      min-width: 179px;
      height: 29px;
      padding: 0 20px;

      font-size: 15px;
      line-height: 17px;
      color: #fff;

      background-color: #73b222;
      border: 1px solid #73b222;
      border-radius: 4px;
    }
  }

  &__top {
    &__section {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 0;
    }
  }

  &__info {
    font-size: 11px;
    color: #999;
    white-space: nowrap;
  }

  &__info_now {
    overflow: hidden;
    flex: 1 0 auto;

    height: 15px;
    margin-left: 10px;

    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    color: #005cc3;
    letter-spacing: 0;

    &:hover {
      color: #c01;
    }
  }

  &__log {
    position: absolute;
    right: 0;
    font-size: 15px;
    letter-spacing: 0;
  }

  &__register {
    color: #005cc3;

    &:hover {
      color: #c01;
    }
  }

  &__login {
    position: relative;
    margin-left: 20px;
    color: #005cc3;

    &::before {
      content: '•';

      position: absolute;
      top: 3px;
      left: -13px;

      display: inline-block;

      font-size: 12px;
      color: #999;
    }

    &:hover {
      color: #c01;
    }
  }

  &__bottom {
    width: 100%;
    margin-top: 56px;
    background-color: #f0f1f4;

    &__section {
      display: flex;
      align-items: center;
      height: 60px;

      & button:last-child {
        &:hover {
          filter: none;
        }
      }
    }
  }
}

.informer {
  padding-top: 40px;
}

.wrapper {
  width: 1000px;
  margin: 0 auto;
}

.button__wrapper {
  margin-left: 15px;
}
