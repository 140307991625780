@import "_generated-font-mixins.scss";
.option {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 12px;

  &__text {
    font-size: 14px;
    color: #555;
  }
}
