@import "_generated-font-mixins.scss";
.main__content {
  flex: 1 0 auto;
  width: 100%;
  padding-bottom: 15px;

  &.bg {
    background: rgb(247 247 247);
  }
}
