@import "_generated-font-mixins.scss";
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 51px;

  background-color: #ececef;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 51px;
    height: 51px;
  }

  &__buttons__block {
    display: flex;
  }
}

.icon__back {
  &::before {
    @include icon-arrow-back;

    font-size: 18px;
    color: #555;
  }
}

.icon__close {
  &::before {
    @include icon-cancel;

    font-size: 18px;
    color: #555;
  }
}

.icon__menu {
  &::before {
    @include icon-aside-btn;

    font-size: 14px;
    color: #555;
  }
}

.icon__search {
  width: 51px;

  &::before {
    @include icon-search-btn;

    font-size: 18px;
    color: #555;
  }
}

.icon__filter {
  &::before {
    @include icon-filter-btn;

    font-size: 18px;
    color: #555;
  }
}

.icon__favorite {
  &::before {
    @include icon-heart;

    font-size: 22px;
    color: #a2a6ab;
  }
}

.active {
  &::before {
    @include icon-fill-heart;

    font-size: 22px;
    color: #c01;
  }
}

.icon__delete {
  &::before {
    @include icon-trash;

    font-size: 20px;
    color: rgb(85 85 85);
  }
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  height: 51px;
}
