@import "_generated-font-mixins.scss";
.sidebar {
  position: absolute;
  top: 0;
  left: -250px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 250px;
  height: 100vh;
  padding-bottom: 50px;

  background-color: #474d59;

  &__link {
    position: relative;

    display: flex;
    gap: 16px;
    align-items: center;

    width: 100%;
    height: 44px;
    padding: 12.5px 15px 12.5px 16px;

    color: #fff;

    border-bottom: 1px solid #424853;
  }
}

.icon__log__out {
  &::before {
    @include icon-log-out;

    color: #b5b8bc;
  }
}

.icon__log__in {
  &::before {
    @include icon-log-in;

    color: #b5b8bc;
  }
}

.icon__registration {
  &::before {
    @include icon-registration;

    color: #b5b8bc;
  }
}
