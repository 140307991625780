@import "_generated-font-mixins.scss";
.scroll__top {
  cursor: pointer;

  position: fixed;
  z-index: 1010;
  left: calc(50vw - 500px - 90px);

  display: none;

  width: 30px;
  height: 30px;

  color: white;

  opacity: 0.3;
  background-color: gray;
  border-radius: 4px;

  &.visible {
    display: block;
  }

  &:hover {
    opacity: 0.5;
  }

  & > img {
    height: auto;
    filter: invert(1);
  }
}
