@import "_generated-font-mixins.scss";
$default-text-color: #999;
$primary-color: #2d82eb;
$rating-color: rgb(252 175 68);

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  min-height: 40px;
  margin-bottom: 15px;
}

@mixin text-styles($gap: 6px, $color: $default-text-color) {
  display: flex;
  gap: $gap;
  align-items: center;

  font-size: 13px;
  color: $color;

  &:not(.rating) {
    margin-right: 15px;
  }
}

@mixin icon-styles($font-size: 14px, $color: $default-text-color) {
  font-size: $font-size;
  color: $color;
}

.author {
  @include text-styles($color: $primary-color);

  &::before {
    @include icon-profile;
    @include icon-styles;
  }
}

.rating {
  @include text-styles($gap: 3px, $color: $primary-color);

  line-height: 1;

  &::before {
    @include icon-star-fill;
    @include icon-styles($color: $rating-color);

    padding-bottom: 4px;
  }
}

.type {
  @include text-styles;

  &::before {
    @include icon-label;
    @include icon-styles;

    transform: rotateZ(90deg);
  }
}

.date {
  @include text-styles;

  &::before {
    @include icon-clock;
    @include icon-styles;
  }
}

.views {
  @include text-styles;

  width: 200px;

  &::before {
    @include icon-eye;
    @include icon-styles;
  }
}

.article {
  padding: 5px 0 18px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgb(60 60 60 / 60%);
}
